if(typeof _sbnaq !== 'undefined'){
	(function ( $ ) {
		'use strict';
		
		$( document ).on( 'zg.product.addedToCart', function ( e, status, productObj, dataSent ) {
			//console.log('trkProductBasketAdd' + productObj.id);
			_sbnaq.push(["trkProductBasketAdd", productObj.id || '']);
		});
		
		$( document ).on( 'zg.product.removedFromCart', function ( e, type, status, data ) {
			if( type === 'cart' ){
				//console.log('trkProductBasketRemove' + data.product_id );
				_sbnaq.push(["trkProductBasketRemove", data.product_id  || '']);
			}
		});

		$( document ).on( 'zg.product.addedToWishlist', function ( e, type, productObj, dataSent ) {
			//console.log('addedToWishlist' + productObj.product_id );
			_sbnaq.push(["trkCustomAction", {"eventCategory": "categoryWishlist","eventAction": "addedToWishlist", "eventValue": productObj.product_id  || ''}]);
		});

		

		
		$('[data-zg-role="product-finder"]').on('submit',function(){
			//console.log($(this).find('[data-zg-role="search-input"]').val());
			var search= $(this).find('[data-zg-role="search-input"]').val();
			_sbnaq.push([ "trkProductLocalSearch" , search ]);
		});
		$('#form_newsletter_modal').on('submit',function(){
			if($(this).valid()) {
				var email = $(this).find('#frmEmailSub').val();
				var privacy = $(this).find('#acceptPrivacy').is(':checked');
				var marketing = $(this).find('#acceptPrivacy').is(':checked');
				_sbnaq.push(["trkNewsletterSubscription", email,'','',privacy,marketing,'']);
			}
		});
		/*if($('body.page_category').lenght != 0){
			window.addEventListener('locationchange', function () {
				console.log(location.href);
				_sbnaq.push(["trkPageView", location.href]);
			});
		}*/
		/*$(document).click('.page_category  #filter-container [data-value]', function(e){
			//_sbnaq.push(["trkCategoryView", ]);
			if($(e.target).data('value')){
				console.log($(e.target).data('value'));
			}
		});*/
		
	}( jQuery ));	
	(function(history){
		var pushState = history.pushState;
		history.pushState = function(state) {
		// YOUR CUSTOM HOOK / FUNCTION
		if($('body.page_category').lenght != 0){			
			_sbnaq.push(["trkAsyncPageChange"]);
			_sbnaq.push(["trkPageView"]);
		}
		return pushState.apply(history, arguments);
		};
	})(window.history);
}
