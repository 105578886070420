// ############################################
// ############### PRODUCT ZOOM ###############
// ############################################

var selectedThumbnail = '[data-zg-role="select-thumb"]';
var containerZoom = '[data-zg-role="zoom-container"]';
var i=0;
var j=0;


$(document).on('zg.gallery.rendered' ,function(){
	$(document).on('click', '[data-zg-role="zoom-new"]' ,function(){
		$('body').addClass('stop-scrolling');
		var selectedMain = $(this).data('zoom-images');
		$(containerZoom).css('background-image', 'url('+ selectedMain +')');
		$('.zoom-wrapper').css('display','block');
		
		//ADD CORRECT SELECTED THUMB
		$('.btn-thumb').each(function(){
			if($(this).attr('data-zoom-images')==$('.thumbnail.active').attr('data-images')){
				$(this).trigger('mouseover');
			}
		});
	});
	if ($('.thumb-slider a.thumbnail').length > 0 ){
		$('.thumb-slider a.thumbnail').click(function(){
			$('.thumb-slider a.thumbnail').removeClass('active');
			$( this ).addClass('active');
		});	
	}
});

$(document).on('click','#prod-swipe-gallery_old img.item-thumb',function(){
	$('body').addClass('stop-scrolling');
	$('.zoom-wrapper-main').css('display','block');
});
$('.zoom-close-main').click(function(e){
	e.preventDefault();
	e.stopPropagation();
	$('body').removeClass('stop-scrolling');
	$('.zoom-wrapper-main').css('display','none');
});
let mx = 0;
let my = 0;
$(document).on('mousedown','#prod-swipe-gallery img.item-thumb', function(e) {
    mx = e.pageX;
	my = e.pageY;
});
$(document).on('mouseup','#prod-swipe-gallery img.item-thumb', function(e) {
	if(mx == e.pageX && my == e.pageY){
		$('body').addClass('stop-scrolling');
		$('.zoom-wrapper-main').css('display','block');
	}
});

$(document).on('click','.zoom-wrapper-main .btn-thumb',function(e) {
	// click on the image, put that one in the zoomMain and open the wrapper-zoom
	$('body').addClass('stop-scrolling');
	const target = e.target;
	var selectedMain = $(target).data('zoom-images');
	$('.btn-thumb').removeClass('selected');
	$(target).addClass('selected');
	$(containerZoom).css('background-image', 'url('+ selectedMain +')');
	$('.zoom-wrapper').css('display','block');
	
	//ADD CORRECT SELECTED THUMB
	$('.btn-thumb').each(function(){
		if($(this).attr('data-zoom-images')==$('.thumbnail.active').attr('data-images')){
			$(this).trigger('mouseover');
		}
	});
	
});
/*$('#zoomMain img').click(function() {
	// click on the image, put that one in the zoomMain and open the wrapper-zoom
	$('body').addClass('stop-scrolling');
	var selectedMain = $('.productGallery .thumbnail.active').data('zoom-images');
	$(containerZoom).css('background-image', 'url('+ selectedMain +')');
	$('.zoom-wrapper').css('display','block');
	
	//ADD CORRECT SELECTED THUMB
	$('.btn-thumb').each(function(){
		if($(this).attr('data-zoom-images')==$('.thumbnail.active').attr('data-images')){
			$(this).trigger('mouseover');
		}
	});
	
});*/

$('.zoom-close').click(function(e){
	e.preventDefault();
	e.stopPropagation();
	$('body').removeClass('stop-scrolling');
	$('.zoom-wrapper').css('display','none');
	j=0;
});

$(document).ready(function(){
	$(document).on('mouseover', selectedThumbnail ,function(){
	var selectedBkg = $(this).attr('data-zoom-images');
		$('.btn-thumb').removeClass('selected');
		$('.zoom-thumb-container div').removeClass('select');
		$(this).addClass('selected');
		$(this).parent().addClass('select');
		$(".zoom-container").css('background-image', 'url('+ selectedBkg +')');
		$("#zoomMain .containerProductImage").attr('src', selectedBkg);
		i=$(".zoom-thumb-container .select").index();
	});
});

var elements = [];
$(document).ready(function(){
	$(".btn-thumb").each(function() {
		var imageLink = $(this).attr("data-zoom-images");
		elements.push(imageLink);
	});
})

$(".productImage-next").on("click", function(e){
	e.preventDefault();
	if(i==elements.length){
		i=0;
	}else{
		i=i+1;
		
		//$("#zoomMain .containerProductImage").attr('src', elements[i]);
		//$(".zoom-container").css('background-image', 'url('+ elements[i] +')');
		//$('.btn-thumb').removeClass('selected');
		//console.log(elements);
		var $elementToClick = $('.thumbnail.active').parent().next().find('.thumbnail');
		if (!$elementToClick.length){
			var $elementToClick = $('.productGallery').find('.thumbnail').first();
		}

		//var index = $elementToClick.data('index');
		//console.log(elements[index]);
		//$(".zoom-container").css('background-image', 'url('+ elements[index] +')');

		$elementToClick.trigger('click');
		if( $elementToClick.parent().hasClass('tns-slide-active') ){}else{
			$('.productGalleryContainer [data-controls="next"]').trigger('click');
		}
	}
})
$(".productImage-before").on("click", function(e){
	e.preventDefault();
	if(i==0){
		i=elements.length;
	}else{
		i=i-1;
		//$("#zoomMain .containerProductImage").attr('src', elements[i]);
		//$(".zoom-container").css('background-image', 'url('+ elements[i] +')');
		//$('.btn-thumb').removeClass('selected');
		//console.log(elements);
		var $elementToClick = $('.thumbnail.active').parent().prev().find('.thumbnail');
		if (!$elementToClick.length){
			var $elementToClick = $('.productGallery').find('.thumbnail').last();
		}

		//var index = $elementToClick.data('index');
		//console.log(elements[index]);
		//$(".zoom-container").css('background-image', 'url('+ elements[index] +')');
		
		$elementToClick.trigger('click');
		if( $elementToClick.parent().hasClass('tns-slide-active') ){}else{
			$('.productGalleryContainer [data-controls="prev"]').trigger('click');
		}
	}
})
$(".productImage-next2").on("click", function(){
	//var $elementToClick = $('.thumbnail.active').parent().next().find('.thumbnail');
	var $elementToClick = $('.zoom-wrapper-main .btn-thumb.selected').parent().next().find('.btn-thumb');
	if (!$elementToClick.length){
		var $elementToClick = $('.zoom-wrapper-main').find('.btn-thumb').first();
	}
	$elementToClick.trigger('click');
	if( $elementToClick.parent().hasClass('tns-slide-active') ){}else{
		$('.zoom-wrapper-main [data-controls="next"]').trigger('click');
	}	
	var selectedMain = $('.zoom-wrapper-main .btn-thumb.selected').data('zoom-images');
	$(".zoom-container").css('background-image', 'url('+ selectedMain +')');
	/*
	if(j==elements.length){
		j=0;
	}else{
		j=j+1;
		$("#zoomMain .containerProductImage").attr('src', elements[j]);
		$(".zoom-container").css('background-image', 'url('+ elements[j] +')');
		console.log(elements);
	}
	*/
})
$(".productImage-before2").on("click", function(){
	/*if(j==0){
		j=elements.length;
	}else{
		j=j-1;
		$("#zoomMain .containerProductImage").attr('src', elements[j]);
		$(".zoom-container").css('background-image', 'url('+ elements[j] +')');
		$('.btn-thumb').removeClass('selected');
		console.log(elements);
	}*/
	var $elementToClick = $('.zoom-wrapper-main .btn-thumb.selected').parent().prev().find('.btn-thumb');
	if (!$elementToClick.length){
		var $elementToClick = $('.zoom-wrapper-main').find('.btn-thumb').last();
	}
	$elementToClick.trigger('click');
	if( $elementToClick.parent().hasClass('tns-slide-active') ){}else{
		$('.zoom-wrapper-main [data-controls="prev"]').trigger('click');
	}
	var selectedMain = $('.zoom-wrapper-main .btn-thumb.selected').data('zoom-images');
	$(".zoom-container").css('background-image', 'url('+ selectedMain +')');
})



//$('.zoom-container').on( "mousemove ", function( e ) {
//	e.preventDefault();
//	e.stopPropagation();
//	var eventY = e.pageY;
//	var docHeight =  $( window ).height();
//	var positionY = (eventY / docHeight) * 100;
//	$(this).css('background-position-y', positionY + '%');
//});
//var touchZoom = false;
//$( ".zoom-container" ).bind('touchstart', function (e){
//	touchZoom = true;
//});
//
//$( ".zoom-container" ).bind('touchend', function (e){
//	touchZoom = false;
//});
//$( ".zoom-container" ).bind('touchmove', function (e){
//	if(touchZoom){
//		var currentY = e.originalEvent.touches[0].clientY;
//		var currentX = e.originalEvent.touches[0].clientX;
//
//		lastY = currentY;
//		var eventY = e.pageY;
//		var docHeight =  $( window ).height();
//		var positionY = (currentY / docHeight) * 100;
//		$(this).css('background-position-y', positionY + '%');
//		var positionX = (currentX / docHeight) * 200;
//		$(this).css('background-position-x', positionX + '%');
//	}
//});


