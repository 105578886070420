/**
 *
 * Plugin for Enhanced Ecommerce
 * - 1. Product Impressions
 * - 2. Product Clicks
 * - 3. Add / Remove from Cart
 * - 4. Promotion Impressions
 * - 5. Promotion Clicks
 * What is not included here is present in global/__google_tag_manager.html
 */

(function ( $ ) {
    'use strict';

    var dataLayer = window.dataLayer || [],
        contProductImpressions = 0;

    function parseCategoryNames( categories ){
        var categoryArray = _.map( categories, function( object ){ return object.name;} );
        return categoryArray.join(' / ');
    }
    /*GA4 REFUND*/ 
    $( document ).on( 'zg-refund-event', function ( e,request){

        var products =request.products;
        var options =request.options;
        var items = [];
        var currency ='';
        for(var product in products){
            
            items.push({
                item_id: products[product].item_id,
                item_name: products[product].item_name,
                coupon:  products[product].coupon,
                currency:  products[product].currency,
                discount:  products[product].discount,
                item_brand:  products[product].item_brand,
                item_category:  products[product].item_category,
                item_category2:  products[product].item_category2,
                item_category3:  products[product].item_category3,
                item_category4:  products[product].item_category4,
                item_category5:  products[product].item_category5,
                item_variant:  products[product].item_variant,
                price:  products[product].price,
                quantity:  products[product].quantity,
                index:product

            });
        }
        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
        event: "refund",
        ecommerce: {
            currency: options.currency,
            transaction_id: options.transaction_id,
            value:options.value,
            coupon: options.coupon,
            shipping: options.shipping,
            items: items
            }
        });
        /*console.log({
        event: "refund",
        ecommerce: {
            currency: options.currency,
            transaction_id: options.transaction_id,
            value:options.value,
            coupon: options.coupon,
            shipping: options.shipping,
            items: items
            }
        });*/
    });

    //GA4 IMPRESSION

    $( document ).on( 'zg.getProductInfo.productRenderedComplete', function ( e,  products ) {

            var list;
            var productsImpressioned=[];
        for(var  i = 0 ; i< products.length; i++){
            contProductImpressions++;
            if (window.SGL_JS_MANAGER === 'search') {
                list = 'Search Results';
            }
            else {
                list = 'Listing';
            }
            /*GA4 EVENT*/
            var cats =[];
            for(var k in products[i].categories){
                cats.push(products[i].categories[k].name)
            }
            var discount =0;
            try{
                discount = products[i].price.to_discount -products[i].price.sell ;
            }catch(e){

            }
            if(discount <= 0) {
                discount = 0;
            }
            if (products[i].default_options){
                 var defaultColor = products[i].default_options[0];
                 var colorSelected = products[i].options[4].values[defaultColor].name;
            }else{
                var defaultColor = false;
                var colorSelected = "";
            }
           
            //console.log(colorSelected);
            var price = 0; 
            for (var key in products[i].characteristics[1204]['values']) {
                price=products[i].characteristics[1204]['values'][key];
            }
            productsImpressioned.push({
                    item_id: products[i].id || '',
                    item_name: products[i].name || '',
                    affiliation: '',
                    coupon: '',
                    currency: window.SGL_JS_CURRENCY,
                    discount: discount,
                    index: i,
                    item_brand: products[i].brand || BRAND, 
                    item_category: cats[0] || '',
                    item_category2: cats[1] || '',
                    item_category3: cats[2] || '',
                    item_category4: cats[3] || '',
                    item_category5: cats[4] || '',
                    item_list_id: list,
                    item_list_name: list,
                    item_variant: colorSelected,
                    location_id: '',
                    price: price,
                    quantity: 1
                    });
        }
        

            
            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            dataLayer.push({
            event: "view_item_list",
            ecommerce: {
                items: productsImpressioned
                }
            });
            /*END GA4 EVENT*/
			//console.log(dataLayer);
    } );
    /**
     * 1. Product Impressions - Every time a product is renedered
     *
     * Required event: zg.getProductInfo.productCreated
     * Required data: element, options, product
     */
    

    /**
     * 2. Product Clicks
     *
     * To track the link, be sure that all link tag have the attr data-zg-role="product-link"
     */
    $( document ).on( 'click', '[data-zg-role="product-link"]', function(){

        if( this.attributes.href ) {

            var parent = $( this ).closest( '[data-zg-role="product"]' ).data( 'zg.product' );
            var productObj = parent.product;
            var categoryNames = parseCategoryNames( productObj.categories );

            dataLayer.push({
                'event': 'productClick',
                'ecommerce': {
                    'click': {
                        'products': [{
                            'name': productObj.name || '', // Name or ID is required.
                            'id': productObj.id.toString() || '',
                            'category': categoryNames || '',
                            'price': productObj.price.sell.toString() || ''
                        }]
                    }
                }
            });
            var cats =[];
            for(var k in productObj.categories){
                cats.push(productObj.categories[k].name)
            }
            var discount =0;
            var list;

            contProductImpressions++;
            if (window.SGL_JS_MANAGER === 'search') {
                list = 'Search Results';
            }
            else {
                list = 'Listing';
            }
            discount = productObj.price.to_discount -productObj.price.sell ;
            if(discount <= 0) {
                discount = 0;
            }
            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            /*var defaultColor = false;
            var colorSelected = "";
            if (products[i].default_options){
                 defaultColor = products[i].default_options[0];
                 colorSelected = products[i].options[4].values[defaultColor].name;
            }
            var defaultColor = productObj.default_options[0];
            var colorSelected = productObj.options[4].values[defaultColor].name;*/
            //GA4 related product 
            dataLayer.push({
            event: "select_item",
            ecommerce: {
                items: [{
                    item_id: productObj.id || '',
                    item_name: productObj.name || '',
                    affiliation: '',
                    coupon: '',
                    currency: productObj.price.currency,
                    discount: discount,
                    index: 0,
                    item_brand: productObj.brand || BRAND, 
                    item_category: cats[0] || '',
                    item_category2: cats[1] || '',
                    item_category3: cats[2] || '',
                    item_category4: cats[3] || '',
                    item_category5: cats[4] || '',
                    item_list_id: list,
                    item_list_name: list,
                    //item_variant: colorSelected,
                    location_id: "",
                    price: productObj.price.sell || '',
                    quantity: 1
                }]
            }
            });
        }
			//console.log(dataLayer);
    });

    /**
     * 3. Add / Remove from Cart
     *
     * Required event: zg.product.addedToCart
     * Required data: status, productObj, dataSent
     */
    $( document ).on( 'zg.product.addedToCart', function ( e, status, productObj, dataSent ) {
			
			
        var categoryname = "";
			
        $.each(productObj.categories, function(key,val) {             
            if( categoryname == '') categoryname = val.label;         
        });    
        var cats =[];
        for(var k in productObj.categories){
            cats.push(productObj.categories[k].name)
        }
			
        dataLayer.push({
            'event': 'addToCart',
            'ecommerce': {
                'currencyCode': productObj.price.currency,
                'add': { // 'add' actionFieldObject measures.
                    'products': [{ //  adding a product to a shopping cart.
                        'name': productObj.name || '',
                        'id': productObj.id.toString() || '',
                        'price': productObj.price.sell.toString() || '',
                        'brand': productObj.brand || BRAND, // Mind to have "BRAND" variable added in global/_common_js_includes.html for adding the "Brand" property in Category page
												'category': categoryname || 'Apparel',
                        'variant': dataSent.sku || '',
                        'quantity': dataSent.quantity || ''
                    }]
                }
            }
        });
        /*var sku = dataSent.sku;
        var skuOptions = productObj.skus[dataSent.sku];
        var sizeSelected = skuOptions.options[2];
        var colorSelected = skuOptions.options[4];
        var valueSize = productObj.options[2].values[sizeSelected].name;
        var valueColor = productObj.options[4].values[colorSelected].name;*/
        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
            currency: productObj.price.currency,
            value: productObj.price.sell * dataSent.quantity,
            items: [{
                item_id: productObj.id || '',
                item_name: productObj.name || '',
                affiliation: '',
                coupon: '',
                currency: productObj.price.currency,
                discount: 0,
                index: 0,
                item_brand: productObj.brand || BRAND,
                item_category: cats[0] || '',
                item_category2: cats[1] || '',
                item_category3: cats[2] || '',
                item_category4: cats[3] || '',
                item_category5: cats[4] || '',
                item_list_id: "",
                item_list_name: " ",
                //item_variant: valueColor,
                location_id: "",
                price: productObj.price.sell || '',
                quantity: dataSent.quantity || ''
                //size: valueSize
            }]
        }
        });
		
				
				//console.log(dataLayer);
    } );



     $( document ).on( 'zg.product.addedToWishlist', function ( e, status, productObj, dataSent ) {			
			
        var categoryname = "";			
        /*$.each(productObj.categories, function(key,val) {             
            if( categoryname == '') categoryname = val.label;         
        });    */
			
        //var defaultColor = productObj.default_options[0];
        //var colorSelected = productObj.options[4].values[defaultColor].name;
        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
        event: "add_to_wishlist",
        ecommerce: {
            items: [{
                item_id: productObj.id || '',
                item_name: productObj.name || '',
                affiliation: '',
                coupon: '',
                currency: SGL_JS_CURRENCY,
                discount: 0,
                index: 0,
                item_brand: productObj.brand || BRAND,
                item_category: window.JS_TRANSLATIONS['category.name'],
                item_list_id: "",
                item_list_name: " ",
                item_variant: "",//colorSelected,
                location_id: "",
                price: productObj.price || '',
                quantity: 1
            }]
        }
        });			
				//console.log(dataLayer);
    } );

    /**
     * Required event: zg.product.removedFromCart
     * Required data: type, status, data
     */
    $( document ).on( 'zg.product.removedFromCart', function ( e, type, status, data ) {

        if( type === 'cart' ){
            // Measure the removal of a product from a shopping cart.
            dataLayer.push({
                'event': 'removeFromCart',
                'ecommerce': {
                    'remove': { // 'remove' actionFieldObject measures.
                        'products': [{ //  removing a product to a shopping cart.
                            'id': data.product_id.toString() || '',
                            'variant': data.sku || '',
                            'quantity': data.quantity || ''
                        }]
                    }
                }
            });
             dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            dataLayer.push({
            event: "remove_from_cart",
            ecommerce: {
                items: [{
                    item_id: data.product_id || '',
                    item_name: data.name || '',
                    affiliation: "",
                    coupon: "",
                    currency:  SGL_JS_CURRENCY,
                    discount: 0,
                    index: 0,
                    item_brand: data.brand || BRAND,
                    item_category: window.JS_TRANSLATIONS['category.name'],
                    item_list_id: "",
                    item_list_name: " ",
                    item_variant: "",
                    location_id: "",
                    price: '',
                    quantity:  data.quantity || ''
                }]
            }
            });
        }
			//console.log(dataLayer);
    } );

    /**
     * For Cart page
     * When a product is removed from cart we are submitting a form. Also when we submitting a form we should trigger zg.product.removedFromCart
     */
    $( document ).on( 'submit', '[data-zg-role="list-item-remove"]', function () {
        var $this = $(this),
            data  = $this.data();

        var dataToSend = {
            'product_id': 	data.id.toString() || 0,
            'sku': 					data.sku || 0,
            'quantity': 		data.quantity || 1
        };

        $( document ).trigger( 'zg.product.removedFromCart', ['cart', null, dataToSend] );
    } );

    /**
     * 4. Promotion Impressions
     *
     * To track the promo present in page, be sure that all promo div have the attr data-zg-role="promotion"
     */
    $( '[data-zg-role="promotion"]' ).each(function( index, element ) {

        dataLayer.push({
            'ecommerce': {
                'promoView': {
                    'promotions': [
                        {
                            'id': element.dataset.promoId || '', // Name or ID is required.
                            'name': element.dataset.promoName || '',
                            'position': element.dataset.promoPosition || ''
                        }]
                }
            }
        });
    });

    /**
     * 5. Promotion Clicks
     *
     * To track the promo link, be sure that all link tag have the attr data-zg-role="promotion-link"
     * Required attr: data-promo-id="x", data-promo-name="x", data-position='x'
     */
    $( document ).on( 'click', '[data-zg-role="promotion-link"]', function() {
        if( this.attributes.href ){
            dataLayer.push({
                'event': 'promotionClick',
                'ecommerce': {
                    'promoClick': {
                        'promotions': [
                            {
                                'id': this.dataset.promoId || '', // Name or ID is required.
                                'name': this.dataset.promoName || '',
                                'position': this.dataset.promoPosition || ''
                            }]
                    }
                }
            });
        }
    });
    
    if($('.page_confirm')){
        $( document ).on( 'zg.selectPayment.paymentReady', function(e,paymentInfo){

            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            dataLayer.push({
            event: "add_payment_info",
            ecommerce: {
                currency: SGL_JS_CURRENCY,
                value: SGL_JS_TOTAL_SUM,
                coupon: "",
                payment_type: paymentInfo,
                items: cart_items
            }
            });
        } );
        $( document ).on( 'zg.selectShipping.shippingReady',function(e,data,val) {

            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            dataLayer.push({
            event: "add_shipping_info",
            ecommerce: {
                currency: val.currency,
                value: val.currency,
                coupon: "",
                shipping_tier: "",
                items: cart_items
                }
            });

        });
    }

}( jQuery ));
