"use strict";
!(function (o) {
        var i = 0;
        jQuery(window).scroll(function (e) {
            var t = o(this).scrollTop();
            165 < t
                ? (o(".header").addClass("scroll"),o("#site_main").addClass("scroll"), i < t ? o(".header").removeClass("header-show") : (o(".header").addClass("header-transition"), o(".header").addClass("header-show")))
                : 0 == t &&
                  (o(".header").removeClass("scroll"),o("#site_main").removeClass("scroll"),
                  o(".header").removeClass("header-show"),
                  setTimeout(function () {
                      o(".header").removeClass("header-transition");
                  }, 500)),
                (i = t);
        }),
            o('a.smooth[href^="#"]').on("click", function (e) {
                e.preventDefault();
                var t = o(this).attr("href");
                if (t.length && 1 < t.length && o(t).length < 1) return !1;
                o("html, body").animate({ scrollTop: o(t).offset().top }, 500);
            }),
            jQuery(document).ready(function () {

                jQuery("#mobile-toggle").click(function () {
                    jQuery(".menu-mobile-slide").addClass("menu-mobile-slide--visible"), jQuery("body").addClass("overflow-hidden");
                }),
                jQuery("#mobile-chiudi").click(function () {
                    jQuery(".menu-mobile-slide").removeClass("menu-mobile-slide--visible"), jQuery("body").removeClass("overflow-hidden");
                }),
                jQuery(".modal-chiudi--caserip").click(function () {
                    jQuery(".modal--caserip").addClass("d-none");
                });
            })
            
    })(jQuery);