/**
 *
 * Manage Dealer from CMS
 *
 * @author: Andrea Basile <abasile[at]zerogrey[dot]com>
 *
 */

/* global _, DEBUG, Promise, google, handlebarsTemplates, JS_TRANSLATIONS */

/**
 * @event document#zg-error Generic error. Used by 2002-zg-notifier.js to display the error
 * @type {object}
 * @property {string} eventType - Typology of event error.
 * @property {string} message - The error message to be translated.
 */

/**
 * @event document#zg-warning Generic warning. Used by 2002-zg-notifier.js to display the warning
 * @type {object}
 * @property {string} eventType - Typology of event message.
 * @property {string} message - The error message to be translated.
 */





(function ( $ ) {
	'use strict';

	/**
	 * @selector data-zg-role="dealers" The plugin start if there is the selector in the dom when the page load
	 */
	var SELECTOR = '[data-zg-role="dealers"]';

	/**

	 * @param {string} [elementCmsContainer] Container element with data for get Dealer from cms
	 * @param {string} [elementFiltersContainer] Container element with data for get filters
	 * @param {string} [elementGetDirections] Button for display directions from user local the specific Dealer
	 * @param {array}  [dealers]
	
	 */

	var DEFAULTS = {


		elementCmsContainer:         '[data-zg-role="get-content-cms"]',
		elementFiltersContainer:     '[data-zg-role="filters"]',
		elementDealersContainer:      '[data-zg-role="pagination"]',

		dealers: null
	};


	// Dealers CLASS DEFINITION
	// ==============================

	/**
	 *
	 * @param {HTMLElement} element
	 * @param {!Object}     options
	 *
	 * @constructor
	 */
	var Dealers = function ( element, options ) {
		this.$element = $( element );

		this.options = _.clone( DEFAULTS );
		this.__setOptions( options );

		this.map = null;

		this.dealers       = {};
		this.parsedDealers = {};
		this.$filtersContainer = $( this.options.elementFiltersContainer );
		this.$cmsContainer     = $( this.options.elementCmsContainer );
	

		/** @type {null|Object} */
		this.filters = null;

		this.infoWindow = null;

		this.ready = new Promise( (function ( resolve, reject ) {			
			}).bind( this ) ).then(
				(function () {

				this.parseDealers( this.options.dealers || this.__getDealersFromCMS() );
			}).bind( this )
		);

		

		this.__setEventHandlers();
	};


	/**
	 *
	 * @param {string} dealerId
	 *
	 * @returns {null|Object}
	 */
	Dealers.prototype.getDealerInfo = function ( dealerId ) {
		var dealerInfo = null;

		if ( this.parsedDealers && this.parsedDealers[dealerId] ) {
			dealerInfo = this.parsedDealers[dealerId];
		}

		return dealerInfo;
	};



	/**
	 * Make sure the dealer information is valid
	 *
	 * @param {Object} dealer
	 * @private
	 */
	Dealers.prototype.parseDealers = function ( dealers ) {
		this.ready.then( (function () {
			var parsedDealers = {};

			// init the dealers object
			if ( _.isString( dealers ) ) {
				dealers = JSON.parse( dealers );
			}

			if ( _.isObject( dealers ) && dealers.fields ) {
				// view dealer page ( single shop ). rebuild the object in the same structure as the list dealers page
				// this shouldn't be necessary if the object is configured properly in the HTML
				dealers = [dealers];
			}

			_.each( dealer, function ( dealer ) {
				parsedDealers[dealer.id] = this.__parseSingleDealer( dealer );
			}, this );

			this.dealers = parsedDealers;


		}).bind( this ) );
	};

	// Dealers PRIVATE METHODS
	// =============================


	/**
	 *
	 * @private
	 */
	Dealers.prototype.initMap = function () {
		this.ready.then( (function () {
			var mapOptions;

			if ( this.map ) {
				this.__reloadMapTiles();
			} else if ( this.options.createMap && this.$mapContainer.length ) {
				// Map options defaults
				// just some sensible defaults
				mapOptions = {
					zoom:      6,
					// We have to provide a default center to initialize the map. Let's use Europe.
					center:    new google.maps.LatLng( 48, 4 ),
					mapTypeId: google.maps.MapTypeId.ROADMAP,
					minZoom:   2,
					maxZoom:   25
				};

				// set map options
				if ( this.options.mapOptions && _.isObject( this.options.mapOptions ) ) {
					_.extendOwn( mapOptions, this.options.mapOptions );
				}

				// set map styles
				if ( this.options.mapStyles && _.isArray( this.options.mapStyles ) ) {
					mapOptions.styles = this.options.mapStyles;
				}


			} else {
				this.$mapContainer.hide();
			}
		}).bind( this ) );
	};


	/**
	 *
	 * @returns {Object}
	 * @private
	 */
	Dealers.prototype.__getDealersFromCMS = function () {
		var origin = this.$cmsContainer.data( 'zg.getContentCMS' );

		// return the contents form the cms or an empty object
		return ( origin && origin.getLatestResponse() || {} ).collection || {};
	};


	/**
	 *
	 * @param {Object} dealer
	 * @returns {Object}
	 * @private
	 */
	Dealers.prototype.__parseSingleDealer = function ( dealer ) {
		if ( !this.parsedDealers[dealer.id] ) {
			this.parsedDealers[dealer.id] = dealer;
		}

		return this.parsedDealers[dealer.id];
	};

	Dealers.prototype.__setEventHandlers = function () {

		// -------------------------------------------------------------------------------------------------------------

		this.$filtersContainer.on( 'zg.filter.applyFilters', (function () {
			if ( this.infoWindow ) {
				this.infoWindow.close();
			}

			this.ready.then( this.mapMarkersMgr.clearMarkers );
		}).bind( this ) );

		this.$filtersContainer.on( 'applyFilters', (function ( e, appliedFilters, filteredItems, filteredKeys ) {
			if ( this.infoWindow ) {
				this.infoWindow.close();
			}

			this.ready.then( (function () {
				this.parseDealers( filteredItems );
			}).bind(this));
		}).bind( this ) );

		// -------------------------------------------------------------------------------------------------------------

		this.$cmsContainer.on( 'zg.getContentCMS.success', (function ( e, contents ) {
			this.ready.then( (function () {
				this.parseDealers( contents );
			}).bind(this));
		}).bind( this ) );


	};


	/**
	 *
	 * @param {Object} options
	 */
	Dealers.prototype.__setOptions = function ( options ) {
		_.extendOwn( this.options, options || {} );
	};




	// DEALERSR PLUGIN DEFINITION
	// ===============================

	var Plugin = function Plugin ( option ) {
		return this.each( function () {
			var $this   = $( this );
			var data    = $this.data( 'zg.dealers' );
			var options = $.extend( {}, window.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

			if ( !data ) {
				$this.data( 'zg.dealers', new Dealers( this, options ) );
			} else if ( option ) {
				data.__setOptions( options );
			}
		} );
	};

	$.fn.Dealers             = Plugin;
	$.fn.Dealers.Constructor = Dealers;


	// DEALERS DATA-API
	// ======================

	$( function () {
		$( SELECTOR ).each( function () {
			Plugin.call( $( this ) );
		} );
	} );

}( jQuery ));
