// ############################################
// ############### PRODUCT READ MORE ###############
// ############################################

$(".btn-readMore").on("click",function(){
		var $this = $(this);
		if($this.hasClass('readMore')){	
			$this.addClass('readLess');
			$this.removeClass('readMore');
			$(".readClose").css("height", "100%");
			$this.text('Chiudi');
		}else{
			var $this = $(this);
			$this.addClass('readMore');
			$this.removeClass('readLess');
			$(".readClose").css("height", "110px");
			$this.text('Leggi di più');		
		}
	});