/**
 * 2904-zg-custom-selector.js
 *
 * Plugin for a custom selector
 *
 * @author: Javier Ramos <framos[at]kooomo.com>
 *
 * Plugin based on : https://github.com/stringyland/custom-select
 *
 */



(function ( $, _ ) {
    'use strict';

    // Establish the root object ('window' in the browser)
    var root = this;

    /**
     * @selector data-zg-role="custom-selector" The plugin start if there is the selector in the dom when the page load
     */
    var SELECTOR = '[data-zg-role="custom-selector"]';



    // SCRIPT OPTIONS

    /**
     * @param {string}  [input] The input element that make the field of the selector
     * @param {string}  [list] ul element
     * @param {string}  [listElement] li elements of the ul
     * @param {string}  [state] State of the selector(initial, opened, filtered, closed)
     * @param {string}  [aOptions]
     */
    var DEFAULTS = {
        input : '',
        list :  '',
        listElement : '',
        state: 'initial',
        aOptions : '',
    };


    // SKELETON-PLUGIN CLASS DEFINITION
    // ===========================

    /**
     * @param {HTMLElement} element
     * @param {!Object}     options
     *
     * @constructor
     */
    var CustomSelector = function ( element, options ) {
        this.$element =         $( element );
        this.options =           _.extendOwn( _.clone( DEFAULTS ), options || {} );

        this.__setEventHandlers();
    };

    CustomSelector.prototype.init = function () {

        this.options.input = this.$element.find( 'input' );
        this.options.list =  this.$element.find( 'ul' );
        this.options.listElements = this.options.list.children( 'li' );
        this.options.aOptions = Array.from( this.options.listElements );

    };

    CustomSelector.prototype.updateOptions = function ( options ) {
        _.extendOwn( this.options, options || {} );
        _.extendOwn( this.request, options || {} );
    };

    /**
     * Open/Close the selector
     *
     */

    CustomSelector.prototype.toggleList = function ( whichWay ) {
        if ( whichWay === 'Open' ) {
            this.options.list.removeClass( 'hidden' );
        } else { // === 'Shut'
            this.options.list.addClass( 'hidden' );
        }
    };

    /**
     * Get the element focused
     *
     */

    CustomSelector.prototype.findFocus = function () {
        var focusPoint = document.activeElement;
        return focusPoint;
    };

    /**
     * Move the focus from one position to another.
     *
     */

    CustomSelector.prototype.moveFocus = function ( fromHere, toThere ) {
        // grab the currently showing options, which might have been filtered
        var aCurrentOptions = this.options.aOptions.filter( function (option ) {
                if ( option.style.display === '' ) {
                    return true;
                }
            }),
            currentItem = '',
            whichOne = '',
            nextOne = '',
            previousOne = '';

        // don't move if all options have been filtered out
        if ( aCurrentOptions.length === 0 ) {
            return;
        }
        if ( toThere === 'input' ) {
            this.options.input.focus();
        }
        // possible start points
        switch ( fromHere ) {
            case this.options.input:
                if ( toThere === 'forward' ) {
                    aCurrentOptions[0].focus();
                } else if ( toThere === 'back' ) {
                    aCurrentOptions[aCurrentOptions.length - 1].focus();
                }
                break;
            case this.options.listElements[0]:
                if ( toThere === 'forward' ) {
                    aCurrentOptions[1].focus();
                } else if ( toThere === 'back' ) {
                    this.options.input.focus();
                }
                break;
            case this.options.listElements[this.options.listElements.length - 1]:
                if ( toThere === 'forward' ) {
                    aCurrentOptions[0].focus();
                } else if ( toThere === 'back' ) {
                    aCurrentOptions[aCurrentOptions.length - 2].focus();
                }
                break;
            default: // middle list or filtered items
                 currentItem = this.findFocus();
                 whichOne = aCurrentOptions.indexOf(currentItem);
                if ( toThere === 'forward' ) {
                    nextOne = aCurrentOptions[whichOne + 1];
                    nextOne.focus();
                } else if ( toThere === 'back' && whichOne > 0 ) {
                    previousOne = aCurrentOptions[whichOne - 1];
                    previousOne.focus();
                } else { // if whichOne = 0
                    this.options.input.focus();
                }
        }
    };

    /**
     * Update the state of the selector
     *
     */

    CustomSelector.prototype.setState = function ( newState ) {
        switch ( newState ) {
            case 'initial':
                this.options.state = 'initial';
                break;
            case 'opened':
                this.options.state = 'opened';
                break;
            case 'filtered':
                this.options.state = 'filtered';
                break;
            case 'closed':
                this.options.state = 'closed';
        }
    };

    CustomSelector.prototype.doFilter = function () {
        var terms = this.options.input.value,
             aFilteredOptions = this.options.aOptions.filter( function (option ) {
                if ( option.innerText.toUpperCase().startsWith( terms.toUpperCase() ) ) {
                    return true;
                }
            });
        this.options.listElements.forEach(
            function ( option ) {
                option.style.display = 'none';
                return option.style.display;
            }
        );
        aFilteredOptions.forEach( function ( option ) {
            option.style.display = '';
        });
        this.setState( 'filtered' );
    };

    /**
     * Do the selection of the option focused
     *
     */

    CustomSelector.prototype.makeChoice = function ( whichOption ) {
        var optionTitle = whichOption.querySelector( '[data-zg-role="option-name"]' );
        this.options.input.val( optionTitle.textContent );
        this.moveFocus( document.activeElement, 'input' );
        $(document).trigger( 'zg.customSelector.makeChoice', whichOption.dataset );
    };


    /**
     * Manage the keyboard keys actions
     *
     */

    CustomSelector.prototype.doKeyAction = function ( whichKey ) {
        var currentFocus = this.findFocus();
        switch ( whichKey ) {
            case 'Enter':
                if ( this.options.state === 'initial' ) {
                    // if state = initial, toggleOpen and set state to opened
                    this.toggleList( 'Open' );
                    this.setState( 'opened' );
                } else if ( this.options.state === 'opened' && currentFocus.tagName === 'LI' ) {
                    // if state = opened and focus on list, makeChoice and set state to closed
                    this.makeChoice( currentFocus );
                    this.options.listElements.each(
                        function (element) {
                            $( this ).removeClass( 'selected' );
                        }
                    );
                    currentFocus.classList.add( 'selected' );
                    this.toggleList( 'Shut' );
                    this.setState( 'closed' );
                } else if ( this.options.state === 'opened' && currentFocus === this.options.input ) {
                    // if state = opened and focus on input, close it
                    this.toggleList( 'Shut' );
                    this.setState( 'closed' );
                } else if ( this.options.state === 'filtered' && currentFocus.tagName === 'LI' ) {
                    // if state = filtered and focus on list, makeChoice and set state to closed
                    this.makeChoice( currentFocus );
                    this.options.listElements.each(
                        function (element) {
                            $( this ).removeClass( 'selected' );
                        }
                    );
                    currentFocus.classList.add( 'selected' );
                    this.toggleList( 'Shut' );
                    this.setState( 'closed' );
                } else if ( this.options.state === 'filtered' && currentFocus === this.options.input ) {
                    // if state = filtered and focus on input, set state to opened
                    this.toggleList( 'Open' );
                    this.setState( 'opened' );
                } else { // i.e. csState is closed, or csState is opened/filtered but other focus point?
                    // if state = closed, set state to filtered? i.e. open but keep existing input?
                    this.toggleList( 'Open' );
                    this.setState( 'filtered' );
                }
                break;
            case 'Escape':
                // if state = initial, do nothing
                // if state = opened or filtered, set state to initial
                // if state = closed, do nothing
                if ( this.options.state === 'opened' || this.options.state === 'filtered' ) {
                    this.toggleList( 'Shut' );
                    this.setState( 'initial' );
                }
                break;
            case 'ArrowDown':
                if ( this.options.state === 'initial' || this.options.state === 'closed' ) {
                    // if state = initial or closed, set state to opened and moveFocus to first
                    this.toggleList( 'Open' );
                    this.moveFocus( this.options.input, 'forward' );
                    this.setState( 'opened' );
                } else {
                    // if state = opened and focus on input, moveFocus to first
                    // if state = opened and focus on list, moveFocus to next/first
                    // if state = filtered and focus on input, moveFocus to first
                    // if state = filtered and focus on list, moveFocus to next/first
                    this.toggleList( 'Open' );
                    this.moveFocus( currentFocus, 'forward' );
                }
                break;
            case 'ArrowUp':
                if ( this.options.state === 'initial' || this.options.state === 'closed' ) {
                    // if state = initial, set state to opened and moveFocus to last
                    // if state = closed, set state to opened and moveFocus to last
                    this.toggleList( 'Open' );
                    this.moveFocus( this.options.input, 'back' );
                    this.setState( 'opened' );
                } else {
                    // if state = opened and focus on input, moveFocus to last
                    // if state = opened and focus on list, moveFocus to prev/last
                    // if state = filtered and focus on input, moveFocus to last
                    // if state = filtered and focus on list, moveFocus to prev/last
                    this.moveFocus( currentFocus, 'back' );
                }
                break;
            default:
                if ( this.options.state === 'initial' ) {
                    // if state = initial, toggle open, doFilter and set state to filtered
                    this.toggleList( 'Open' );
                    this.doFilter();
                    this.setState( 'filtered' );
                } else if ( this.options.state === 'opened' || this.options.state === 'closed' ) {
                    // if state = opened or closed, doFilter and set state to filtered
                    this.doFilter();
                    this.setState( 'filtered' );
                } else { // already filtered
                    this.doFilter();
                }
        }
    };

    /**
     *
     * @private
     */
    CustomSelector.prototype.__setEventHandlers = function () {
        var that = this;

        this.$element.on( 'click.zg.customSelector', function (e) {
            console.log('click');
            var currentFocus = that.findFocus();
            console.log('currentFocus start of click:',currentFocus);
            switch ( that.options.state ) {
                case 'initial' : // if state = initial, toggleOpen and set state to opened
                    that.toggleList('Open');
                    that.setState('opened');
                    break;
                case 'closed': // if state = closed, toggleOpen and set state to filtered? or opened?
                    that.toggleList( 'Open' );
                    that.setState( 'filtered' );
                    break;
                default:
                    // if state = opened or filtered and focus on input, toggleShut and set state to initial
                    if (currentFocus === that.options.input.get(0)) {
                        that.toggleList('Shut');
                        that.setState('initial');
                    } else if (currentFocus.tagName === 'LI') {
                        // if state = opened and focus on list, makeChoice, toggleShut and set state to closed
                        that.options.listElements.each(
                            function (element) {
                                $( this ).removeClass( 'selected' );
                            }
                        );
                        currentFocus.classList.add( 'selected' );
                        that.makeChoice( currentFocus );
                        that.toggleList( 'Shut' );
                        that.setState( 'closed' );
                    }
            }
        } );

        this.$element.on( 'keydown.zg.customSelector', function (e) {
            that.doKeyAction( e.key );
            e.preventDefault();
        } );

        $( document ).on( 'click', function (e) {

            if ( !e.target.closest( '[data-zg-role="custom-selector"]' ) ) {
                // click outside of the custom group
                that.toggleList( 'Shut' );
                that.setState( 'initial' );
            }

        } );

    };


    // SKELETON-PLUGIN DEFINITION
    // ============================

    function Plugin ( option, updateOptions ) {
        return this.each( function () {
            var $this   = $( this );
            var data    = $this.data( 'zg.customSelector' );
            var options = $.extend( {}, root.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

            if ( !data ) {
                $this.data( 'zg.customSelector', ( data = new CustomSelector( this, options ) ) );
            } else if ( updateOptions && typeof option === 'object' ) {
                data.updateOptions( option );
            }

            data.init();
        } );
    }

    $.fn.zg_custom_selector              = Plugin;
    $.fn.zg_custom_selector.Constructor = CustomSelector;


    // SKELETON-PLUGIN DATA-API
    // ===================

    // default custom selector - called on page load
    $( function () {
        $( SELECTOR ).each( function () {
            Plugin.call( $( this ) );
        } );
    } );

}.call( this, jQuery, _ ));