/**
 * Plugin for managing subscriptions on the eshop
 */

/* global  JS_TRANSLATIONS, zgPost, handlebarsRender */

(function ($, _) {

    'use strict';

    var root = this;

    /**
     * @selector The plugin start if there is the selector in the dom when the page load
     */

    var SELECTOR = '[data-zg-role="subscription"]';

    var DEFAULTS = {
        cancelSubscriptionBtn :       '[data-zg-role="cancel-subscription"]',
        subscriptionOrderId :      'subscription-order-id',
        selectPlan:                '[data-zg-role="select-plan"]',
        alert:                     '[data-zg-role="alert"]',
        proceedCheckout:           '[data-zg-role="proceed-checkout"]',
        planAppliedId: -1,
        planAppliedName: '',
        cancelButtonEnabled:        true
    };

    /**
     *
     * @param {HTMLElement} element
     * @param {!object}     options
     *
     * @constructor
     */

    var Subscription = function ( element, options ) {

        this.$element                   = $( element );
        this.options                    = _.extendOwn( _.clone( DEFAULTS ), options || {} );
        this.$alert                     = $( this.options.alert );
        this.$proceedCheckout           = $( this.options.proceedCheckout );
        this.$cancelSubscriptionBtn     = $( this.options.cancelSubscriptionBtn )

        this.__eventHandlers();
    };


    Subscription.prototype.init = function () {
        if( this.options.planAppliedName.length ) {
            this.renderAlertInfo("subscription.thankYouChoosing" );
        }
    };

    Subscription.prototype.renderAlertInfo = function ( titleKeyValue ) {
        var data = {
            planName: this.options.planAppliedName,
            titleKey: titleKeyValue,
            descriptionKey: 'subscriptions.order_product_' + this.options.planAppliedName
        };
        this.$alert.html( handlebarsTemplates.render( "subscription-alert", data ) );
        this.$alert.removeClass( 'hidden' );
    };

    Subscription.prototype.cancelSubscription = function ( orderSubscriptionId ) {
        var that = this;
        var action = 'cancelOrderSubscription';
        var data = { subscription_id: orderSubscriptionId };
        var msg = { error: JS_TRANSLATIONS.cancel_subscription_id_failed };
        var callback = {
            success: function () {
                $( document ).trigger( 'zg-notification', [{
                    eventType: 'zg.subscriptions',
                    message:   JS_TRANSLATIONS['cancel_subscription_id_success']
                }] );
                that.$cancelSubscriptionBtn.addClass( 'hidden' );
            },
            error: function () {
                $( document ).trigger( 'zg-error', [{
                    eventType: 'zg.subscriptions',
                    message:   JS_TRANSLATIONS['cancel_subscription_id_error']
                }] );
                that.options.cancelButtonEnabled = true;
            }
        };

        zgPost( action, data, msg, callback );

    };


    Subscription.prototype.subscribeToPlan = function ( planId ) {
        var that = this;
        var action = 'selectSubscriptionPlan';
        var data = { subscription_plan_id: planId };
        var msg = { error: JS_TRANSLATIONS.eshop_cart_subscription_plan_id_selection_failed };
        var callback = {
            success: function () {
                that.renderAlertInfo("subscription.thankYouChoosing" );
            },
            error: function () {}
        };

        zgPost( action, data, msg, callback );
    };


    Subscription.prototype.__eventHandlers = function () {
        var that = this;
        this.$cancelSubscriptionBtn.on( 'click.zg.cancelSubscription', function (e) {
            if( that.options.cancelButtonEnabled ) {
                that.options.cancelButtonEnabled = false;
                var orderSubscriptionId = $( this ).data( that.options.subscriptionOrderId );
                that.cancelSubscription( orderSubscriptionId) ;
            }
        });

        this.$element.on( 'change.zg.subscription', this.options.selectPlan, function ( e ) {
            that.options.planAppliedId = e.target.value;
            that.options.planAppliedName = $( that.options.selectPlan + " option:selected").text();
            that.subscribeToPlan( e.target.value );
        });

        $( this.$proceedCheckout ).on( 'click.zg.subscription', function( e ) {
            // If there is no Subscription Plan selected yet, we will trigger a notification
            // and not allow the user to Proceed to purchase until a Plan is not selected first
            if( !that.options.planAppliedName.length ) {
                e.preventDefault();
                $( document ).trigger( 'zg-warning', [{
                    eventType: 'zg.subscriptions',
                    message:   JS_TRANSLATIONS['subscription.pleaseChoosePlan']
                }] );
            }
        });

         $( document ).on( 'zg.customSelector.makeChoice', function ( e, dataSetObject ){
            that.options.planAppliedId = dataSetObject.optionValue_1;
            that.options.planAppliedName = dataSetObject.optionValue_2;
            that.subscribeToPlan( dataSetObject.optionValue_1 );
        } );
    };

    function Plugin ( option ) {
        return this.each( function () {
            var $this   = $( this );
            var data    = $this.data( 'zg.subscription' );
            var options = $.extend( {}, root.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

            if ( !data ) {
                $this.data( 'zg.subscription', data = new Subscription( this, options ) );
            }

            data.init();
        });
    }

    $.fn.zg_subscription             = Plugin;
    $.fn.zg_subscription.Constructor = Subscription;

    //START PLUGIN
    $(function () {
        $(SELECTOR).each(function () {
            Plugin.call($(this));
        });
    });


}).call( this, jQuery, _ );
