function changeBackgroundColorHeader () {
  var scroll = $(window).scrollTop();
  var backgroundPercent = scroll / 200;
  $('#site_header.header-transparent').css('background-color', 'rgba(44,54,114,' + backgroundPercent + ')');
}

$(document).on({
  scroll: function () {
    changeBackgroundColorHeader();
    }, ready: function () {
    changeBackgroundColorHeader();
    }
});

$('.img-parallax').each(function(){
  var img = $(this);
  var imgParent = $('#site_main');
  function parallaxImg () {
    var speed = img.data('speed');
    var scroll = $(window).scrollTop();
    var imgPercent = (((scroll / 1000) * 10) + (50 - (speed * 50)) -100)*10;
    var backgroundPercent = scroll / 200;
    img.css({
      //top: imgPercent + '%',
      transform: 'translate(0, -' + imgPercent + '%)'
    });
  }

  $(document).on({
    scroll: function () {
      parallaxImg();
    }, ready: function () {
      parallaxImg();
    }
  });
});