/**
 * zg-cartCustomFields.js
 *
 * Send, with ajax call, all cart custom fields of the form.
 *
 * @author Pietro Vignola <pvignola(at)kooomo(dot)com>
 *
 */

/* global zgPost */

(function ( $ ) {
    'use strict';

    var SELECTOR = '[data-zg-role="cart_custom_fields"]';

    var CartCustomFields = function ( element ) {
        this.$element = $( element );
        this.__send();
        $(SELECTOR).on('change','input,select',function(e){
            this.__send();
        }.bind(  this ));
    };

    CartCustomFields.prototype.__getFormData = function () {
        return this.$element.serializeArray();
    };

    CartCustomFields.prototype.__send = function () {
				$.ajax( {
					type:     'POST',
					url:      window.makeUrl( { module: 'eshop', manager: 'eshop', action: 'setCustomFieldsToCart' } ),
					data:     this.__getFormData(),
					dataType: 'json',
					success: function ( response ) {
							this.__onSuccess( response );
					}.bind(  this ),
					error: function () {
							this.__onError();
					}.bind(  this )
				} );
    };

    CartCustomFields.prototype.__onError = function () {
        throw new Error( 'Error on setCustomFieldsToCart' );
    };

    CartCustomFields.prototype.__onSuccess = function () {
        console.log('js/base/2902-zg-cartCustomFields.js - Cart custom fields properly set in session');
    };

    function Plugin () {
        return this.each( function () {
            var $this = $( this );
            var data = $this.data( 'zg.cartCustomFields' );

            $this.data( 'zg.cartCustomFields', (data = new CartCustomFields( this )) );
        } );
    }

    $.fn.cartCustomFields = Plugin;
    $.fn.cartCustomFields.Constructor = CartCustomFields;

    $( function () {
        $( SELECTOR ).each( function () {
            Plugin.call( $( this ) );
        } );
    });

}).call( this, jQuery );