// add your site specific javascript here
$( document ).ready(function() {
	'use strict';
	
	// Header burger icon
	$('.btn-burger').on('click', function () {
		$('.animated-burgerIcon').toggleClass('open');
	});
	
	$.ajaxSetup( {
		cache: true
	} );

	//For crawler
	if ( !window.JS_ENABLED ) {
		$.ajax( {
			url:      window.makeUrl( { module: 'eshop', action: 'setJavascriptEnabled' } ),
			type:     'post',
			dataType: 'json'
		} );
	}

	// Search header
	if ($ ('.top-bar-cerca').length ) {
		var $searchTopBar = $( '.top-bar-cerca' );
		$( 'a.mostra-ricerca' ).click( function(){
			
			if( $searchTopBar.hasClass( 'd-none' ) ){
				$searchTopBar.removeClass( 'd-none' );
			}
			else {
				$searchTopBar.addClass( 'd-none' );
			}
		});
	}

	/*Slider Box Cerca HP
	if ($('.my-slider').length != 0 ){ 
 		mySlider();
	}*/

	// Slider Box Cerca HP
	//if ($('.component-box-cerca').length != 0 ){ 
 		//homeBoxCerca();
	//}

	// Slider products HP
	if ($('.homePageProduct').length != 0 ){ 
 		homePageProductSlider();
	}

	//progress bar free shippping
	if ($('.progressbar_container').length ){
		progressBarFreeShipping();
	}
	function progressBarFreeShipping(){
		const progress = document.querySelector(".progressbar_main-done");
		const minValue = document.querySelector(".helper-div").getAttribute('data-done');
		const cartValue = document.querySelector(".helper-div-2").getAttribute('data-done');
		(cartValue / minValue)*100 < 100 ? progress.style.width =  (cartValue / minValue)*100 + '%' : progress.style.width = 100 + '%'; 
		const result= document.querySelector('.result');
		let resultdiff = toFixed((minValue-cartValue),2);
		resultdiff > 0 ? result.innerHTML=""+resultdiff+"" : 
			(document.getElementById("not-completed").classList.add("d-none"),
			document.getElementById("announce").classList.add("d-none"),
			document.getElementById("completed").classList.remove("d-none")
			);
	}
	function toFixed(num, fixed) {
		var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
		return num.toString().match(re)[0];
	}
	// END Progress bar free shipping

	/*function homeBoxCerca(){
		var slider = tns({
		container: '.slider-boxCerca',
		items: 1,
		mouseDrag: 'mouseDrag',
		autoplay: true,
		autoplayButton: '.auto',
		autoplayText: ['Start', 'Stop'],
		controlsContainer: '#controls',
		prevButton: '.previous',
		nextButton: '.next',
		});
	}*/

	
	function homePageProductSlider(){
		var slider = tns({
		"container": ".slider_homePageProduct",
		"nav": false,
		"items": 1,
		"controls": true,
		"rewind": true,
		"controlsPosition": "bottom",
		"mode": "carousel",
		"swipeAngle": false,
		"speed": 400, 
		"controlsText": [" "," "],
		"loop": false,
		"responsive": {
		"992": {
		"items": 4,
		"controlsPosition": "bottom",
		"swipeAngle": false,
		"speed": 400,
		"gutter": 7, 
		"controlsText": [" "," "],
		"loop": false			
			}}
		});
	}

	// Slider category HP
	if ($('.categorySlider').length != 0 ){ 
		categorySlider();
	}
			function categorySlider(){
				var slider = tns({	
				"container": ".slider_categoryHP",
				"nav": true,
				"navPosition": 'bottom',
				"items": 5,
				"left": true,
				"loop" : true,
				"edgePadding": 15,	
				"gutter": 15,
				"controlsPosition": "bottom",
				"mode": "carousel",
				"swipeAngle": false,
				"speed": 400,
				"controlsText": [" "," "],
				"responsive": {
				"992": {
				"fixedWidth": 500,
				"items": 2,
				"gutter": 15,
				"edgePadding": 0		
				}}
			});
			}
	// Slider category HP
	if ($('#prod-swipe-gallery').length != 0 ){ 
		var slider = tns({	
			"container": "#prod-swipe-gallery",
			"nav": true,
			"controls": true,
			"items": 1,
			"left": true,
			"loop" : true,
			"edgePadding": 15,	
			"gutter": 15,
			"controlsPosition": "center",
			"mode": "carousel",
			"navContainer": '.thumb-slider',
			"arrowKeys":true,
			"mouseDrag": true,
			"prevButton":'.prev-swipe-main',
			"nextButton":'.next-swipe-main',
			"responsive": {
				"992": {
					"mouseDrag": false
				}
			}
		});
		var slider = tns({	
			"container": "#thumb-slider",
			"nav": false,
			"items": 4,
			"left": true,
			"loop" : true,
			"edgePadding": 15,	
			"gutter": 15,
			"mode": "carousel",
			"touch": true,
			"mouseDrag": true,
			"prevButton":'.prev-swipe',
			"nextButton":'.next-swipe',
			"responsive": {
				"992": {
					"items": 8
				}
			}

		});
	}
	
	


	/* Confirm */
	$(document).ready(function() {

		$(document).on('click','[data-zg-role="checkout-continue"]',function(e){
			var radioValue = $('[name="cart_custom_fields[Car_delivery]"]:checked').val();
			if ( radioValue.slice(0, 2) != "No") {
				
				var $this = $( '#form-store select' );
				var $option = $( '#form-store select option:selected' );
				//var api_token = '1RA7NfAyMWl9ogyndwJztS6J2gtz5csq';
				var api_token = 'X4uEqPohxamjf13Wrxt43Stq8ftKIdPU';	
				var from_cap =$this.data('cap');;
				var from_location = $this.data('location');;
				var to_cap = $option.data('cap');
				var to_location = $option.data('location');
				var marker = $this.data('marker');
				var model = $this.data('model');
				var eurotax = $this.data('eurotax');
				var height = $this.data('height');
				var length = $this.data('length');
				var width = $this.data('width');
				var vtype = $this.data('type');

				if (eurotax != ''){
					if (vtype == 'vcom') {
						var data = JSON.stringify ({ api_token: api_token, from_cap: from_cap, from_location: from_location, to_cap: to_cap, to_location: to_location, vehicles: [{maker: marker, model: model, eurotax: eurotax, type: vtype }] });
					}else{
						var data = JSON.stringify ({ api_token: api_token, from_cap: from_cap, from_location: from_location, to_cap: to_cap, to_location: to_location, vehicles: [{maker: marker, model: model, eurotax: eurotax }] });
					};
				}else{
					var data = JSON.stringify ({ api_token: api_token, from_cap: from_cap, from_location: from_location, to_cap: to_cap, to_location: to_location, vehicles: [{maker: marker, model: model, height: height, length: length, width: width }] });
				}

				$.ajax({
					type: 'POST',
					url:  'https://www.karrycar.it/api/quotes',
					data: data,
					success: function(data) { console.log('data: ' + data); },
					contentType: "application/json",
					dataType: 'json'
				});

			

  			}
		});

  		$('input[name="cart_custom_fields[Car_delivery]"]:radio').change(function(e) {
    		let value = e.target.value.trim().slice(0, 3);
			if (value == "Yes"){
				$('#form-store').css('display','block');
				$('#form-store select').prop('selectedIndex',0);
			}else{
				$('#form-store').css('display','none');
			}
		});

		
			$(document).on('change','#form-store select',function(e){
			var $this = $( this );
			var $option = $( '#form-store select option:selected' );
			//var api_token = '1RA7NfAyMWl9ogyndwJztS6J2gtz5csq';
			var api_token = 'X4uEqPohxamjf13Wrxt43Stq8ftKIdPU';	
			var from_cap =$this.data('cap');;
			var from_location = $this.data('location');;
			var to_cap = $option.data('cap');
			var to_location = $option.data('location');
			var marker = $this.data('marker');
			var model = $this.data('model');
			var eurotax = $this.data('eurotax');
			var height = $this.data('height');
			var length = $this.data('length');
			var width = $this.data('width');
			var vtype = $this.data('type');

			var url = 'https://www.karrycar.it/api/quotes?api_token=' + api_token + '&from_cap=' + from_cap + '&from_location=' + from_location + '&to_cap=' + to_cap + '&to_location=' + to_location + '&maker=' + marker + '&model=' + model;
			if (eurotax != ""){
				url = url + '&eurotax=' + eurotax;
				if (vtype == 'vcom') url = url + '&type=' + vtype;
			}
			
			url = url + '&height=' + height + '&length=' + length + '&width=' + width + "&only_prices=1";

			$.ajax( {
				type:     'get',
				url:       url,
				dataType: 'json',

				success: function ( response ) {
					//console.log(response);
					$('#form-store').find('[data-zg-role="response"]').html('<br /><b>Tempi di consegna:</b> ' + response.days + ' giorni<br /><b>Costo della consegna:</b> ' + response.price + ' &euro;');
				}
			} );
			
			
		});

		
		
	});

	$(document).ready(function() {
  		$('input[name="cart_custom_fields[Veicolo in permuta]"]:radio').change(function(e) {
    		let value = e.target.value.trim();
			console.log(value);
			if (value == "Yes"){
				$('#form-exchange').css('display','block');
			}else{
				$('#form-exchange').css('display','none');
			}
		});
	});
	
	
	

	//Return to top
	$( window ).scroll( function() {
		if ( $( this ).scrollTop() > 200) {        // If the page is scrolled more than 200px
			$( '#toTop' ).fadeIn( 200 );
		} else {
			$( '#toTop' ).fadeOut( 200 );
		}
	});
	$( '#toTop' ).click( function() {
		console.log("clicked button!");
		$( 'body,html' ).animate({
			scrollTop : 0
		}, 500);
	});

	var $searchContainer = $( '#searchContainer' );
	$( '[data-zg-role="btn-search"]' ).click( function(){
		if( $searchContainer.hasClass( 'd-none' ) ){
			$searchContainer.removeClass( 'd-none' );
		}
		else {
			$searchContainer.addClass( 'd-none' );
		}
	});
	
	 


	//Increasing-decreasing qty input
	$( '[data-zg-role="change-qty"]' ).click( function(){
		event.preventDefault();
		var type = $( this ).data( 'type' );
		var $inputQtySibling = $( this ).parent().siblings( '[data-zg-role="quantity"]' );
		var maxQty = $inputQtySibling.data( 'max' );
		var floatQty = $inputQtySibling.data( 'floatQty' );
		var qty = $inputQtySibling.val();
		var step;

		if( floatQty ){
			step = 0.01;
		}
		else {
			step = 1;
		}
		qty = Number(qty);

		$( '[data-zg-role="change-qty"]' ).attr( 'disabled', false );
		if( type == "minus" ){
			//If qty is 0, we will disable the current minus icon.
			//The next time this function will be run, all the change qty icons will be enabled again.
			if( qty == 0 ){
				$( this ).attr( 'disabled', true );
			}
			else{
				qty = qty - step;
			}
		}
		else {
			//If qty is equal to maxQty, we will disable the current plus icon.
			//The next time this function will be run, all the change qty icons will be enabled again.
			if( qty == maxQty ){
				$( this ).attr( 'disabled', true );
			}
			else {
				qty = qty + step;
			}
		}

		$( this ).parent().siblings( '[data-zg-role="quantity"]' ).val( qty );
	});
	
	/* Toggle class active in bootstrap collapse */
	$('[data-toggle="collapse"]').click(function() {
  	$(this).toggleClass( "active" );
	});
	
	
	/* active tabs for Cart checkout login*/
	
	/*$(".login-checkout-tab li").click(function() {
		$(".login-checkout-tab li").removeClass("high-custom"); 
		$(this).addClass("high-custom"); 
		$(".login-checkout-tab li").removeClass("active");
		$(".login-checkout-tab li a").removeClass("active");
		$(this).addClass("active");
		$(this).find("a").addClass("active");
	});*/

	$(".login-checkout-tab li").click(function() {
		$(".login-checkout-tab li").removeClass('high-custom'); 
		$(this).addClass('high-custom'); 
		$(".login-checkout-tab li").removeClass('active');
		$(this).addClass('active'); 
	});
	
	/*(custom checkbox radio */
	
	$('.nav-item-checkout a').click(function() {
			$(".input-payment").prop('checked', false);
			$(".input-payment").prop('checked', true);
  });
	
	$('#0-tab').click(function() {
			$(".input-payment").prop('checked', false);
			$("#paymentRadios0").prop('checked', true);
  });
	

	
	/* CATEGORY PAGE SORT BY DROPDOWN */


	$('.dropdown .dropdown-item').on('click', function(){
    $('#show').text($(this).text());
	});
	$(document).ready(function() {
		$('#show').text($(".dropdown-desktop .dropdown-item[id='default-option']").text());
	});
	
	
	$(document).on('click','[data-zg-role="openFilterMobile"]',function(e){
		e.preventDefault();
		e.stopPropagation();
		var $this = $( this );
		var id = $( this ).data('open');
		
		if ( $('#'+id).hasClass('is-open') ){
			$('body').removeClass( 'stop-scrolling' );
			//$('.btn[data-open=' + id + ']').removeClass('is-open');
			$('#'+id).removeClass('is-open');
			closeNav( id );
		}else{
			$('body').addClass( 'stop-scrolling' );
			//$('.btn[data-open=' + id + ']').addClass('is-open');
			$('#'+id).addClass('is-open');
			openNav( id );
		}
		
		//$( this ).toggleClass('is-open')
		
	});
	
	
	/*Added to calculate the height to center the arrows regard to the image height*/
	function heightArrowsSlider( slider ) {
		var topHeight = ( $( '.'+slider ).find( 'img' ).height()/100*60);
		$( '.slick-arrow' ).css( 'top' , topHeight+'px' );
	}

	$( '.slider-related-post' ).on( 'init', function( slick ) {
		heightArrowsSlider( 'slider-related-post' );
	});

	$( window ).on( 'resize' , _.debounce( function() {
		heightArrowsSlider( 'slider-related-post' );
	}, 200));

/*	$( '.slider-related-post' ).slick({
		dots: false,
		infinite: true,
		speed: 400,
		slidesToShow: 3,
		slidesToScroll: 3,
		adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});

	*/

/*
$(document).on('click','[data-zg-role="open-nav"]',function(e){
	e.preventDefault();
	closeAllNav();
	$( this ).toggleClass('nav-active');
	
	var $this = $( this );
	var id = $( this ).data('open');
	$('.sidenavBackground').css('display','block');
	setTimeout(function(){ $('.sidenavBackground').addClass('active'); }, 100);
	
	
	if ( $this.hasClass('navbar-toggle') ){ 
		
		if ( $this.hasClass('is-open') ){
			closeAllNav();
		}else{
			openNav( id );
		}
		$( this ).toggleClass('is-open');
		
	}else{
		openNav( id );
	}
	
	
});


$(document).on('click','[data-zg-role="close-nav"]',function(e){
	e.preventDefault();
	
	var $this = $( this );
	var id = $( this ).data('close');
	$('.sidenavBackground').removeClass('active');
	setTimeout(function(){ 
		$('.sidenavBackground').css('display','none');
		closeNav( id );
	}, 300);
	

});

* Set the width of the side navigation to 250px*
function openNav(id) {
	
	

	if( id.indexOf('sidenavMenuMobile') !== -1 || id == 'sidenavFiltersMobile' || id == 'sidenavSortMobile'){
		document.getElementById(id).style.left = "0px";
	}else{
		closeAllNav();
		document.getElementById(id).style.right = "0px";
	}
	if($(window).width()>=480) { 
		document.getElementById("overlay-toggle").style.display = "block";
	}
	
	$('.opensidebar').removeClass("opensidebar");
	$('#'+id).addClass("opensidebar");
}

*Set the width of the side navigation to 0*
function closeNav(id) {
	var w=$(document.getElementById(id)).width();

	if( id.indexOf('sidenavMenuMobile') !== -1 || id == 'sidenavFiltersMobile'|| id == 'sidenavSortMobile'){
		document.getElementById(id).style.left = "-"+w+"px";
	}else{
		document.getElementById(id).style.right= "-"+w+"px";
	}
	//document.body.style.backgroundColor = "white";
	document.getElementById("overlay-toggle").style.display = "none";
	//$('#'+id).removeClass("open");
	
	$('.opensidebar').removeClass("opensidebar");
	$( '.navbar-toggle' ).removeClass('is-open');
	$( '.nav-active' ).removeClass('nav-active');
} 

function closeAllNav() {
	$('.sidenav').css( 'right','' );
}

$("#overlay-toggle").click(function() { 
	$( '.nav-active' ).removeClass('nav-active');
	closeAllNav();
});

$('[data-zg-role="close-all"]').click(function() { 
	closeAllNav();
});
*/

/* HEADER */
/* dropdown menu in main header menu open with hover event */
$('#site_header #navbarNav .nav-item').hover(function() {
	$(this).find('.dropdown-menu').stop(true, true).delay(100).fadeIn(100);
	statusOpen();
}, function() {
	$(this).find('.dropdown-menu').stop(true, true).delay(100).fadeOut(100);
	statusClose();
});

function statusOpen (){
	$('body').addClass( 'stop-scrolling' );
	$('#site_header').addClass( 'menuOpen' );
}

function statusClose (){
	$('body').removeClass( 'stop-scrolling' );
	$('#site_header').removeClass( 'menuOpen' );
}
	
/* main menu header for mobile version*/
/* open/close version navbar menu mobile */
$(document).on('click','[data-zg-role="openNavbarMobile"]',function(e){
	e.preventDefault();
	e.stopPropagation();
	var $this = $( this );
	var id = $( this ).data('open');
	
	statusClose();
	
	if ( $this.hasClass('is-open') ){
		$(`.navbar-toggler[data-open=${id}]`).removeClass('is-open');
		closeNav( id );
	}else{
		$(`.navbar-toggler[data-open=${id}]`).addClass('is-open');
		statusOpen();
		openNav( id );
	}
});
	
/* Set the width of the side navigation to 0 */
function closeNav(id) {
	document.getElementById(id).style.left = "-100%";
	$('#' + id).toggleClass('openNavActive');
}

/* Set the width of the side navigation to 100% */
function openNav(id) {
	document.getElementById(id).style.left = "0";
	$('#' + id).toggleClass('openNavActive');
}
	
	/* collapse main menu */
	$('#navbarNav.navbar-collapse').on('show.bs.collapse', function() {
		$('#navbarSearch .dropdown-menu').removeClass('show');
		$('#navbarCart .dropdown-menu').removeClass('show');
		setTimeout(function() { 
			statusOpen();
    }, 50);
	});
	$('#navbarNav.navbar-collapse').on('hide.bs.collapse', function () {
		statusClose();
	});
	/**/
	
	/* manage open/close nav tool for mobile version*/
	$('#navbarCart').on('show.bs.dropdown', function () {
		$('body').addClass( 'stop-scrolling' );
		$('#site_header').addClass( 'cartOpen' );
	});
											
	$('#navbarCart').on('hide.bs.dropdown', function () {
		$('body').removeClass( 'stop-scrolling' );
		$('#site_header').removeClass( 'cartOpen' );
	});
	
	$('#navbarSearch').on('show.bs.dropdown', function () {
		$('#navbarNav.navbar-collapse').removeClass('show');
		//statusOpen();
		$('#site_header').addClass( 'searchOpen' );
	});
	
	$('#navbarSearch').on('hide.bs.dropdown', function () {
		statusClose();
		$('#site_header').removeClass( 'searchOpen' );
	});
	
	function statusOpen (){
		$('body').addClass( 'stop-scrolling' );
		$('#site_header').addClass( 'menuOpen' );
		
	}
	
	function statusClose (){
		$('body').removeClass( 'stop-scrolling' );
		$('#navbarSearch .dropdown-menu').removeClass('show');
		$('#navbarCart .dropdown-menu').removeClass('show');
		$('#site_header').removeClass( 'menuOpen' );
		$('#site_header').removeClass( 'searchOpen' );
		$('#site_header').removeClass( 'cartOpen' );
	}
	
/**/


	$(document).on('click','[data-zg-role="next-image"]',function(e){
		e.preventDefault();
		console.log('next image');
		if ($('.gallery-product a.active').closest( "div" ).next().length){
			$('.gallery-product a.active').closest( "div" ).next().find('a').trigger('click');
		}else{
			$('.gallery-product a:first').trigger('click');
		}
		
		
	});

/* FORM CONCESSIONARIA LANDING*/
if($('#redirect_landing').length !=0){
	$( document ).on( 'syn-sended-email',function(e){
		//console.log(e);
		//console.log('MAIL INVIATA');
		location.href=$('#redirect_landing').val();
	});
}

if($('.cnt_703089 #province').length != 0){
	var province = {
		'AG' : 'Agrigento',
		'AL' : 'Alessandria',
		'AN' : 'Ancona',
		'AO' : 'Aosta',
		'AR' : 'Arezzo',
		'AP' : 'Ascoli Piceno',
		'AT' : 'Asti',
		'AV' : 'Avellino',
		'BA' : 'Bari',
		'BT' : 'Barletta-Andria-Trani',
		'BL' : 'Belluno',
		'BN' : 'Benevento',
		'BG' : 'Bergamo',
		'BI' : 'Biella',
		'BO' : 'Bologna',
		'BZ' : 'Bolzano',
		'BS' : 'Brescia',
		'BR' : 'Brindisi',
		'CA' : 'Cagliari',
		'CL' : 'Caltanissetta',
		'CB' : 'Campobasso',
		'CI' : 'Carbonia-Iglesias',
		'CE' : 'Caserta',
		'CT' : 'Catania',
		'CZ' : 'Catanzaro',
		'CH' : 'Chieti',
		'CO' : 'Como',
		'CS' : 'Cosenza',
		'CR' : 'Cremona',
		'KR' : 'Crotone',
		'CN' : 'Cuneo',
		'EN' : 'Enna',
		'FM' : 'Fermo',
		'FE' : 'Ferrara',
		'FI' : 'Firenze',
		'FG' : 'Foggia',
		'FC' : 'Forlì-Cesena',
		'FR' : 'Frosinone',
		'GE' : 'Genova',
		'GO' : 'Gorizia',
		'GR' : 'Grosseto',
		'IM' : 'Imperia',
		'IS' : 'Isernia',
		'SP' : 'La Spezia',
		'AQ' : 'L\'Aquila',
		'LT' : 'Latina',
		'LE' : 'Lecce',
		'LC' : 'Lecco',
		'LI' : 'Livorno',
		'LO' : 'Lodi',
		'LU' : 'Lucca',
		'MC' : 'Macerata',
		'MN' : 'Mantova',
		'MS' : 'Massa-Carrara',
		'MT' : 'Matera',
		'ME' : 'Messina',
		'MI' : 'Milano',
		'MO' : 'Modena',
		'MB' : 'Monza e della Brianza',
		'NA' : 'Napoli',
		'NO' : 'Novara',
		'NU' : 'Nuoro',
		'OT' : 'Olbia-Tempio',
		'OR' : 'Oristano',
		'PD' : 'Padova',
		'PA' : 'Palermo',
		'PR' : 'Parma',
		'PV' : 'Pavia',
		'PG' : 'Perugia',
		'PU' : 'Pesaro e Urbino',
		'PE' : 'Pescara',
		'PC' : 'Piacenza',
		'PI' : 'Pisa',
		'PT' : 'Pistoia',
		'PN' : 'Pordenone',
		'PZ' : 'Potenza',
		'PO' : 'Prato',
		'RG' : 'Ragusa',
		'RA' : 'Ravenna',
		'RC' : 'Reggio Calabria',
		'RE' : 'Reggio Emilia',
		'RI' : 'Rieti',
		'RN' : 'Rimini',
		'RM' : 'Roma',
		'RO' : 'Rovigo',
		'SA' : 'Salerno',
		'VS' : 'Medio Campidano',
		'SS' : 'Sassari',
		'SV' : 'Savona',
		'SI' : 'Siena',
		'SR' : 'Siracusa',
		'SO' : 'Sondrio',
		'TA' : 'Taranto',
		'TE' : 'Teramo',
		'TR' : 'Terni',
		'TO' : 'Torino',
		'OG' : 'Ogliastra',
		'TP' : 'Trapani',
		'TN' : 'Trento',
		'TV' : 'Treviso',
		'TS' : 'Trieste',
		'UD' : 'Udine',
		'VA' : 'Varese',
		'VE' : 'Venezia',
		'VB' : 'Verbano-Cusio-Ossola',
		'VC' : 'Vercelli',
		'VR' : 'Verona',
		'VV' : 'Vibo Valentia',
		'VI' : 'Vicenza',
		'VT' : 'Viterbo',
	};
	$.each(province, function(index, value){
      $('#province').append('<option value="'+index+'">'+index+' - '+value+'</option>')
    });
}

	
});

	$(".dropdown-menu-mobile a.dropdown-item").click(function() {
		//var datadd = $(this).data('dropdown');
		//console.log("data:", datadd);
		/*$(".login-checkout-tab li").removeClass('high-custom'); 
		$(this).addClass('high-custom'); 
		$(".login-checkout-tab li").removeClass('active');
		$(this).addClass('active'); */

		const datadd = $(this).data('dropdown');
			switch (datadd) {
			case 'details':
				$('#collapse-details').addClass('show');
				$('#collapse-details').removeClass('d-none');
				$('#collapse-address').addClass('d-none');
				$('#collapse-newsletter').addClass('d-none');
				$('#collapse-tickets').addClass('d-none');
				break;
			case 'address':
				$('#collapse-address').addClass('show');
				$('#collapse-address').removeClass('d-none');
				$('#collapse-details').addClass('d-none');
				$('#collapse-newsletter').addClass('d-none');
				$('#collapse-tickets').addClass('d-none');
				break;
			case 'newsletter':
				$('#collapse-newsletter').addClass('show');
				$('#collapse-newsletter').removeClass('d-none');
				$('#collapse-address').addClass('d-none');
				$('#collapse-details').addClass('d-none');
				$('#collapse-tickets').addClass('d-none');
				break;
			case 'tickets':
				$('#collapse-tickets').addClass('show');
				$('#collapse-tickets').removeClass('d-none');
				$('#collapse-newsletter').addClass('d-none');
				$('#collapse-address').addClass('d-none');
				$('#collapse-details').addClass('d-none');
			    break;
			}
	});
	
	$(".dropdown-menu-mobile-help a.dropdown-item").click(function() {
		console.log('click');
		const dataHelp = $(this).data('zg-info');
		//console.log('dataHelp:', dataHelp);

		const dataName = $(this).data('dropdown');
		//console.log('dataName:', dataName);
		document.getElementById("dropdownMenuButtonHelp").innerHTML = dataName;
		document.getElementById("title-help").innerHTML = dataName;
		

		$('[data-zg-info="help-content"]').hide().removeClass('show');
		const idHelpContent = '#' + dataHelp;
		$(idHelpContent).show().addClass('show');
		//console.log('idHelpContent:', $(idHelpContent));
		
	}); 



  if ($('.product_gallery_slider').length != 0 ){ 
            var slider = tns({
            "container": ".product_gallery_slider",
            "items": 2,
			"controls": false,
			"gutter": 0,
			"navPosition": "bottom",
			"mode": "carousel",
			"speed": 300,
			"rewind": true,
			"nav" : true,
			//"fixedWidth": 830,
			"responsive": {
				"350": {
				"items": 1,
				"fixedWidth": 390,
				},
				"500": {
				"items": 2,
				"fixedWidth": false,
				}
			},
        });
    };



	if ($('.suggestedProductsSliderFirst').length != 0 ){ 
		setTimeout(function(){  
            var slider = tns({
				"container": ".suggestedProductsSliderFirst",
				"items": 2,
				//"controls": false,
				"gutter": 0,
				"controlsContainer": '#custom-controlFirst',
				"navPosition": "bottom",
				"mode": "carousel",
				"speed": 300,
				"rewind": true,
				"nav" : true,
				//"fixedWidth": 830,
				"responsive": {
					"450": {
					"items": 1,
					},
					"500": {
					"items": 2,
					},
					"1200":{
						"items": 5,
						"rewind": true,
						"gutter": 0
					}
				},
        	});
		}, 2000);
    };


	if ($('.suggestedProducts').length != 0 ){ 

		$('.suggestedProducts').addClass('loading');

		setTimeout(function(){  
			$( ".suggestedProducts" ).each(function( index ) {

				var sliderId = $(this).find('[data-zg-role="get-product"]').attr('id') || $(this).find('[data-zg-role="get-product-bck"]').attr('id');
				var controlsId = $(this).find('.control').attr('id');

				$( this ).removeClass('loading');


				var slider = tns({
					"container": "#" + sliderId,
					"items": 2,
					//"controls": false,
					"gutter": 0,
					"controlsContainer": "#" + controlsId,
					"navPosition": "bottom",
					"mode": "carousel",
					"speed": 300,
					"rewind": true,
					"nav" : false,
					//"fixedWidth": 830,
					"responsive": {
						"350": {
						"items": 1,
						},
						"500": {
						"items": 2,
						},
						"1200":{
							"items": 5,
							"rewind": true,
							"gutter": 0
						}
					},
				});
			});	
		}, 2000);
    };


if ($('#loginTransform').length != 0 ){ 
	document.getElementById('loginTransform').addEventListener('click', function() {
		document.getElementById('regisContainer').classList.toggle('d-block');
		document.getElementById('regisContainer').classList.toggle('d-none');
		document.getElementById('logContainer').classList.toggle('d-none');
	})
};

if ($('#registerTransform').length != 0 ){ 
	document.getElementById('registerTransform').addEventListener('click', function() {
		document.getElementById('regisContainer').classList.toggle('d-block');
		document.getElementById('regisContainer').classList.toggle('d-none');
		document.getElementById('logContainer').classList.toggle('d-none');
		
	})
};


$( document ).ready(function() {
	if( $('[data-zg-role="filter-home-btn"]').length ){

		window.history.replaceState({}, '', 'it');

		$('[data-zg-role="filter-home-btn"]').on('click', function(e) {
			e.preventDefault();
			var currentURL = window.location.href;
			var sURLVariables = currentURL.split('?');
			var goTo = $( this ).attr('href');
			if(sURLVariables[1]) goTo = goTo+'?'+sURLVariables[1];
			window.location.replace(goTo);
		});
	}
	
});	

// bring the email in the form
if ($('#newsletter_form_footer').length) {
	let openNlModal = document.querySelector('[data-target="#newsletterModal"]');
	let nlValue;
	openNlModal.addEventListener('click', () => {
		nlValue = document.querySelector('[data-element="nl-input"]').value;
	})
	$('#newsletterModal').on('show.bs.modal', function (event) {
		var modal = $(this)
		modal.find('#frmEmailSub').val(nlValue)
	})
}


// in pp chose only one between 'Acquista' - 'Noleggio' - 'Leasing'
if ($('.page_product').length){
	let modalita =	document.querySelectorAll('[data-element="modalita"]') ;
	let inputModalita = document.querySelector('[data-element="modalitaInput"]')
	let index;
	let modalitaList = [...modalita];
	[...modalita].map((el, i)=>{
		el.addEventListener('click', ()=>{
			el.classList.add("active");
			index = i;
			inputModalita.value = el.textContent; 
			(modalitaList).map((value,key)=>{
				if(key != index){
					value.classList.remove('active') ?? null;
				}
			})

			//console.log(el.textContent);

			document.querySelector('[data-zg-role="price-Acquista"]').classList.add('d-none');
			document.querySelector('[data-zg-role="price-Noleggio"]').classList.add('d-none');
			document.querySelector('[data-zg-role="price-Finanziamento"]').classList.add('d-none');
			document.querySelector('[data-zg-role="price-' + el.textContent + '"]').classList.remove('d-none') ?? null;
			

		});
	})
}


$('.SeeMore2').click(function(){
		var $this = $(this);
		$this.toggleClass('SeeMore2');
		if($this.hasClass('SeeMore2')){
			$this.text('Leggi tutto');
			$('#accordion2 .accordion-heading').removeClass('d-none');			
		} else {
			$this.text('Chiudi');
			$('#accordion2 .accordion-heading').addClass('d-none');
		}
	});






$('.cars_section .carousel').carousel({
  interval: 10000
})

$('.cars_section .carousel .carousel-item').each(function(){
    var minPerSlide = 3;
    var next = $(this).next();
    if (!next.length) {
    next = $(this).siblings(':first');
    }
    next.children(':first-child').clone().appendTo($(this));
    
    for (var i=0;i<minPerSlide;i++) {
        next=next.next();
        if (!next.length) {
        	next = $(this).siblings(':first');
      	}
        
        next.children(':first-child').clone().appendTo($(this));
      }
});

$(window).on('load', function() {
	if( $('.mobile-bar').length ){
		zE('messenger', 'hide');
	}
	if( $('[data-zg-role="open-chat"]').length ){

		$('[data-zg-role="open-chat"]').on('click', function(e) {
			e.preventDefault();
			zE('messenger', 'show');
			zE('messenger', 'open');
			$('.mobile-bar').hide();
		});

		zE('messenger:on', 'close', function() {
			$('.mobile-bar').show();
		});
	}
	
});
	


$( document ).ready(function() {
	if( $('.productGallery').length ){
		var slider = tns({
			"container": ".productGallery",
			"nav": false,
			"items": 4,
			"controls": true,
			"rewind": true,
			"controlsPosition": "bottom",
			"mode": "carousel",
			"swipeAngle": false,
			"speed": 400, 
			"controlsText": ["<i class='fas fa-chevron-left'></i>","<i class='fas fa-chevron-right'></i>"],
			"loop": false,
			"responsive": {
				"992": {
					"items": 8,
					"controlsPosition": "bottom",
					"swipeAngle": false,
					"speed": 400,
					"gutter": 0, 
					"controlsText": ["<i class='fas fa-chevron-left'></i>","<i class='fas fa-chevron-right'></i>"],
					"loop": false			
				}
			}
		});
	}	
});	

$( document ).ready(function() {
	$( document ).on('zg.product.addedToWishlist', function (event,request,self) {
		//console.log(request.product_id);
		//console.log(self);
		//console.log(self.$element);
		if (self.$element.data('name')){
			$('form[name="wishlistform"] [name="Automobile"]').val(self.$element.data('name'));
			$('form[name="wishlistform"] [name="subject"]').val("Un utente ha aggiunto la tua auto alla wishlist: " + self.$element.data('name'));
		}
		if (self.$element.data('concessionaria')){
			$('form[name="wishlistform"] [name="Concessionaria"]').val(self.$element.data('concessionaria'));
		}
		$('form[name="wishlistform"] [name="Product_id"]').val(request.product_id);
		if( $('#wishlistform').length){
			$('#wishlistform').trigger('click');
		}
	})

	/* COOKIE REMINDER */
	if(!window.user_id){
		$('[data-role="submit-reminder"]').on('click',function(){
			if($(this).parents('form').valid()){
			//remove timer
			clearTimeout(stt);
			zgEraseCookie('cookie_reminder_navigation_init_timer');
			zgEraseCookie('cookie_reminder_first_timer');
			zgEraseCookie('cookie_reminder_second_timer');
			//submit form
			$(this).parents('form').submit();
			}
		});
		$( document ).on( 'zg.product.addedToCart', function ( e, status, productObj, dataSent ) {
			/* RICOMINCIO IL TIMER */
			clearTimeout(timerReminder);
			let init = Date.now();
			zgCreateCookie('cookie_reminder_navigation_init_timer', init);
			zgCreateCookie('cookie_reminder_first_timer', window.cookie_reminder.first_timer);
			zgCreateCookie('cookie_reminder_second_timer', window.cookie_reminder.second_timer);
			
			timer_reminder();		
		});

		let stt;

		const timerReminder = name => {
			$('[data-zg-role="cookie_reminder"]').modal('show');
			clearTimeout(stt);
			if(name == 'cookie_reminder_first_timer'){
				zgEraseCookie('cookie_reminder_first_timer');
				let init = Date.now();
				zgCreateCookie('cookie_reminder_navigation_init_timer', init);
				timer_reminder();
			}else if(name == 'cookie_reminder_second_timer'){
				zgCreateCookie('cookie_reminder_navigation_init_timer',Date.now());
				timer_reminder();
			}
		};

		function timer_reminder(){
			let init_reminder= 0,first_reminder = 0, second_reminder=0;
			/* check if timer first and second are expired  */
			if(zgReadCookie('cookie_reminder_navigation_init_timer')){
				init_reminder = zgReadCookie('cookie_reminder_navigation_init_timer');
			}
			if(zgReadCookie('cookie_reminder_first_timer')){
				first_reminder = zgReadCookie('cookie_reminder_first_timer');
			}
			if(zgReadCookie('cookie_reminder_second_timer')){
				second_reminder = zgReadCookie('cookie_reminder_second_timer');
			}		
			
			if(init_reminder != 0){
				let rest_reminder =(Date.now()-init_reminder)/1000;
				
				/* START TIMER */
				if(first_reminder != 0){
					/* STAR FIRST TIMER */
					if(rest_reminder <= first_reminder){
						let timerf=first_reminder-rest_reminder;
						stt = setTimeout(timerReminder, parseInt( timerf) * 1000, 'cookie_reminder_first_timer');
					}else{
						stt = setTimeout(timerReminder,  1000, 'cookie_reminder_first_timer');
					}
					
				}else if(second_reminder != 0){
					/* STAR SECOND TIMER */
					if(rest_reminder <= second_reminder){
						let timers=second_reminder-rest_reminder;
						stt = setTimeout(timerReminder, parseInt( timers) * 1000, 'cookie_reminder_second_timer');
					}else{
						stt = setTimeout(timerReminder, 1000, 'cookie_reminder_second_timer');
					}
				}
			}
		}
		
		function expire_timer(name){
			/* show modal and delete timer if first */
		}
		/* if cart is empty */
		function delete_timer(){
			zgEraseCookie('cookie_reminder_navigation_init_timer');
		}
		$( document ).on( 'zg.getProductListInfo.success', function ( e, type, data, showCart  ) {
			clearTimeout(stt);
			if(type == "cart"){
				if(data.products_in_cart.length != 0){
					timer_reminder();
				}
			}
			
		});
	}
	
	/* END COOkie reminder */
});	




$( document ).ready(function() {
	if( $('#form_newsletter_prod_contact').length ){
		$(document).on('change','#contatta-il-venditore [name="email"]',function(e){
			var value = $( this ).val(); 
			$( '#form_newsletter_prod_contact [name="frmEmail"]' ).val(value); 
		});	

		$(document).on('submit','#contatta-il-venditore',function(e){
			$( '#form_newsletter_prod_contact button' ).trigger('click'); 
		});	




	var forms = $('#form_newsletter_prod_contact')
	
	forms.each(function (index, el) {
		var clearEmailInput = function() {
			$(el).find('input[type="email"]').val('')
		}
		
		var privacyField = $(el).find('input[name="acceptPivacy"]')
		var action = $(this).attr('action')
		
		$(el).on('submit', function(e) {

			e.preventDefault()
			if(privacyField.is(':checked')) {
				if($(el).valid()) {
					var dataArray = $(this).serializeArray()
					var formData = new FormData(this)

					$.ajax({
						url: action,
						type: 'POST',
						cache: false,
						dataType: "html",
						data: $(this).serialize(),
						contentType: 'application/x-www-form-urlencoded',		

						beforeSend: function ( xhr ) {
							xhr.setRequestHeader('X-Requested-With', 'empty');
						},
					})

					.done(function(response, status) {
						/*
						var htmlResponse = $(response);
						console.log(response);
						
						var alreadyregistered = htmlResponse.find('.user-already-registered').length > 0

						if (alreadyregistered) {
							$.gritter.add( {
								title:      window.JS_TRANSLATIONS['notifier.notification'] || 'Notification',
								class_name: 'alert alert-success',
								text: 	JS_TRANSLATIONS.already_nl_ok.substring( 0, 40 ),
								time: 1000000,
							} );

							clearEmailInput()
						} else {
							$.gritter.add( {
								title:      window.JS_TRANSLATIONS['notifier.notification'] || 'Notification',
								class_name: 'alert alert-success',
								text: 	JS_TRANSLATIONS.nl_email_to_confirm.substring( 0, 40 ),
								time: 1000000,
							} );

							clearEmailInput()
						}
						*/
					})

					.error(function (error) {
						console.log('Error', error)
					})
				} else {
					var mailError = $(el).find('#ns_email-error')	
					mailError.css('color', 'red')
					setTimeout(function () {
							mailError.css('color', 'black')
					}, 3000)
				}
			}
		})
	})





	}


	if( $('#print-sheet').length ){
		/*PRINT PDF */
		function isEmail(email) {
			var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
			return regex.test(email);
		}
		document.getElementById("print_prod").onclick = function () {
			if($('#print-sheet .email-input').val() != ''){
				$('#print-sheet').submit();
				printElement(document.getElementById("site_main"));	
			}else{
				$('#sheet_email').modal('show')
			}
		}

		function printElement(elem) {	
			window.print();
		}
		$('#sheet_email #request_sheet').on('click', function () {
			var em = $('#sheet_email #email-for-sheet').val();
			var privacy_check = $('#sheet_email #acceptPrivacy').is(":checked");
			if(isEmail(em)){
				$('#print-sheet .email-input').val(em);
				
				if(privacy_check == false ){
					$('#sheet_email .help-block.privacy').removeClass('hidden');
				}else{
					$('#print-sheet').submit();
					$('#sheet_email').modal('hide');
					printElement(document.getElementById("site_main"));	
				}	
			}else{
				$('#sheet_email .help-block.email').removeClass('hidden');
			}
		});
		$('#sheet_email #email-for-sheet').on('change', function () {
			var em = $('#sheet_email #email-for-sheet').val();
			if(isEmail(em)){
					$('#sheet_email .help-block.email').addClass('hidden');
			}else{
				$('#sheet_email .help-block.email').removeClass('hidden');
			}
		});
		$('#sheet_email #acceptPrivacy').on('change', function () {
			var privacy_check = $('#sheet_email #acceptPrivacy').is(":checked");
			if(privacy_check == true ){
				$('#sheet_email .help-block.privacy').addClass('hidden');
			}else{
				$('#sheet_email .help-block.privacy').removeClass('hidden');
			}
		});
	}


});	